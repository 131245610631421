<template>
  <div id="app">
    <Cenik />
  </div>
</template>

<script>
import Cenik from './components/Cenik'

export default {
  name: 'App',
  components: {
    Cenik
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2b2a29;
  background-color: #ffffff;
}

body {
    background-color: #ffffff;
}
</style>
