<template>
    <b-container style="max-width:576px;">
        <b-row>
            <b-col :cols="12" class="mt-3 mb-3">
                <label for="zaposleni">Zaposleni</label>
                <b-form-input type="number" min="1" max="100" step="1" id="zaposleni" v-model="vnos.zaposleni" style="max-width: 200px;"></b-form-input>
            </b-col>
            <b-col :cols="12">
                <b-form-checkbox v-model="vnos.sms" switch>&nbsp;SMS obveščanje</b-form-checkbox>
                <b-form-checkbox v-model="vnos.uporabnikFM" switch>&nbsp;Uporabnik FinMaster</b-form-checkbox>
            </b-col>
        </b-row>
        <hr style="border-top:2px solid #f07953;margin:30px 0;">
        <b-row class="mt-4">
            <b-col :cols="7" style="text-align:left;"><h4>Cena</h4></b-col>
            <b-col :cols="5" class="text-right"><h4>{{ cenaF }} &euro;</h4></b-col>
            <b-col style="text-align:left;margin-top:15px;font-size:0.9rem;" v-if="ugodnostVeljavnaDo"><p>*Brezplačna poskusna uporaba za dva meseca, na voljo do {{ ugodnostVeljavnaDo }}.</p></b-col>
            <b-col :cols="12" style="text-align:left;font-size:0.9rem;">Prikazane cene ne vključujejo DDV.</b-col>
        </b-row>
        <b-row>
            <b-col style="text-align:center;margin-top:20px;">
                <b-button class="btn-red" @click="resetiraj()" size="sm"><b-icon icon="arrow-counterclockwise" style="margin-right:5px;"></b-icon>Resetiraj</b-button>
                <b-button class="btn-red" @click="shrani()" size="sm"><b-icon icon="download" style="margin-right:7px;"></b-icon>Shrani</b-button>
                <p class="print-only">Datum: {{ datum }}</p>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import moment from 'moment'
moment.locale('sl')

export default {
    name: 'Cenik',
    data() {
        return {
            vnos: {
                zaposleni: 10,
                sms: false,
                uporabnikFM: false
            },
            cenik: false,
        }
    },
    mounted() {
        this.pridobiCenik();
    },
    methods: {
        pridobiCenik() {
            this.axios
            .get('https://portal.finmaster.si/api/prices-action.php?act=get&app=MD')
            .then((res) => {
                console.log(res.data);
                this.cenik = res.data;
            })
        },
        resetiraj() {
            this.vnos.zaposleni = 10;
            this.vnos.sms = false;
            this.vnos.uporabnikFM = false;
        },
        shrani() {
            print();
        }
    },
    computed: {
        cena: function() {
            if (!this.cenik) return 0;

            let c = this.cenik;
            var price = this.vnos.zaposleni*parseFloat(c['cenaZaposleni']);
            if (this.vnos.sms) price += this.vnos.zaposleni*parseFloat(c['cenaSms']);

            price = Math.max(price,parseFloat(c['minZnesek']));
            if (this.vnos.uporabnikFM) price *= 1 - parseInt(c['popustFM'])/100;

            return price;
        },
        cenaF: function() {
            var price = this.cena.toFixed(2);
            price += '';
            return price.replace(".",",");
        },
        ugodnostVeljavnaDo: function() {
            if (!this.cenik) return false;

            let date = moment(this.cenik['datumVeljaveUgodnosti']);
            if (date.isBefore(moment())) return false;

            return date.format('D.M.YY');
        },
        datum: function() {
            return moment().format('dddd, D. MMMM YYYY');
        }
    }
}
</script>
<style>
h3 {
    font-size: 1.4rem !important;
}
label {
    font-weight: 500;
}
.custom-switch {
    margin-top: 5px;
}
.btn-red {
    background-color: #f07953 !important;
    border: 1px solid #f07953 !important;
    margin: 10px 8px;
}
.btn-red:hover {
    background-color: #ed5c2c !important;
}
.print-only {
    display: none;
}
.custom-control-input:checked~.custom-control-label:before {
    background-color: #f07953 !important;
    border-color: #ed5c2c !important;
}

@media print {
    .btn-red {
        display: none;
    }
    .print-only {
        display:block;
    }
    .container {
        margin-top:60px;
    }
}
</style>